.flexible {
  display: flex;
  flex-direction: column;
  flex: 1;
}

input {
  &:hover,
  &:focus {
    border-color: grey;
  }
}

// MARK: sidebar
p-sidebar {
  div.p-sidebar-content {
    @extend .flexible;
  }
}

.nav-sidebar .p-sidebar-content {
  display: flex;
}

div.p-panelmenu-header-content,
div.p-panelmenu-content {
  border: none !important;
  background-color: transparent !important;
  @extend .flexible !optional;
}

// MARK: calendar
.custom-p-calendar {
  background-color: white;
  border: 1px gray solid;

  &.hourly-report-calendar {
    div.p-datepicker-monthpicker {
      left: 0 !important;
      width: 250px !important;
      min-width: auto !important;
    }
  }

  span.p-calendar {
    background-color: transparent;
    display: flex;
    flex-direction: row-reverse;

    input.p-inputtext {
      width: auto;
      border-radius: 0;
      border-color: white;
      box-shadow: none;
    }

    button.p-datepicker-trigger {
      background-color: transparent;
      border: none;

      calendaricon {
        color: gray;

        &:hover {
          color: var(--red-color);
        }
      }
    }
  }
}

// MARK: table
p-table {
  div.p-datatable-wrapper {
    border-radius: 10px;
  }

  p-paginator {
    div.p-paginator {
      border-radius: 0 0 10px 10px;
      position: relative;

      span.p-paginator-current {
        cursor: default;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
      }
    }
  }
}

.custom-table-actions {
  span.p-button-icon {
    color: black;
  }

  span.p-button-label {
    font-weight: lighter;
  }
}

// MARK: tooltip
.custom-tooltip {
  .p-tooltip-text {
    width: 90%;
    margin-right: 10%;
    border-radius: 0px;
    background-color: #f7fdfd;
    color: black;
  }

  .p-tooltip-arrow {
    display: none;
  }
}

// MARK: Spinner
.custom-spinner .p-progress-spinner-circle {
  stroke: grey !important;
}

// MARK: MediaQueries
@media (max-width: 640px) {
  p-table p-paginator div.p-paginator span.p-paginator-current {
    display: none;
  }
}

@media (max-width: 500px) {
  .custom-p-calendar {
    background-color: transparent;
    border: none;

    span.p-calendar {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;

      input.p-inputtext {
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        visibility: hidden;
      }

      button.p-datepicker-trigger {
        border-radius: 100%;
        background-color: transparent;
        border: none;

        &:hover {
          background-color: gray;
          border: 1px gray solid;
        }

        calendaricon {
          transform: scale(1.5);
          color: white;
        }
      }
    }
  }
}
