/* You can add global styles to this file, and also import other style files */
@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @import 'tailwindcss/base';
}

@layer tailwind-utilities {
  @import 'tailwindcss/components';
  @import 'tailwindcss/utilities';
}

@font-face {
  font-family: 'Montserrat'; // Nombre de la fuente
  src: url('../public/fonts/Montserrat-Regular.ttf'); // Ruta al archivo .ttf
}

@font-face {
  font-family: 'ArialNarrow'; // Nombre de la fuente
  src: url('../public/fonts/arial_narrow.ttf'); // Ruta al archivo .ttf
}

* {
  font-family: 'Montserrat';
}

*:focus {
  box-shadow: none !important;
}

html,
body {
  overflow: hidden;
  margin: 0px !important;
  padding: 0px !important;
  font-size: 12px;
}

:root {
  --font-arial-narrow: 'ArialNarrow';

  --red-color: #d51f36 !important;
  --sky-color: #28beb1 !important;
  --grey-color: #f5f5f5 !important;
  --grey-hover: #e5e5e5 !important;
  --aqua-color: #eefbfa !important;
}

.flexible {
  display: flex;
  flex-direction: column;
  flex: 1;
}
